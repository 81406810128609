<template>
  <div class="coupon-exchange-setting">
    <PageTitle
      title="第三方消費紀錄"
      btn="匯出"
      @btnClick="modal.export = true"
    />
    <FiltersContainer>
      <!-- 交易時間 -->
      <el-date-picker
        v-model="search.timeRange"
        style="width: 340px;"
        type="datetimerange"
        range-separator="至"
        start-placeholder="開始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd HH:mm"
        :default-time="['00:00:00', '23:59:59']"
        clearable
        @change="refresh(true)"
        @clear="refresh(true)"
      />
      <BaseSearch
        :value.sync="search.branchStores"
        :api="apiFormatAdaptor(GetShops)"
        collapse-tags
        multiple
        placeholder="請選擇分店"
        @change="refresh(true)"
      />
    </FiltersContainer>
    <ExternalTransactionRecordTable
      v-loading="loading.table"
      :tableData="tableData"
      :enable-columns="['shopName']"
      :externalData="{shopList}"
      @refresh="refresh(false)"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <ExportOptionsDialog
      v-if="modal.export"
      allRange
      @close="modal.export = false"
      @export="prepareExport"
    />
    <ExportDialog
      v-if="exportState.modal"
      title="匯出各分店消費紀錄"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import ExternalTransactionRecordTable from '@/components/ExternalTransaction/ExternalTransactionRecordTable.vue'
import {
  OrgAdminGetMemberExternalTransaction as GetMemberExternalTransaction,
  OrgAdminGetMemberExternalTransactionCount as GetMemberExternalTransactionCount,
  OrgAdminGetMemberExternalTransactionSourceOptions as GetMemberExternalTransactionSourceOptions,
} from '@/api/memberExternalTransaction'
import { useFetch } from '@/use/fetch'
import { memberExternalTransactionStatusConfig, originsConfig } from '@/config/externalTransaction'
import { GetShops } from '@/api/shop'
import BaseSearch from '@/components/Search/BaseSearch.vue'
import { get, map, compact, forEach, join, find } from 'lodash'
import { useExport } from '@/use/export'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import { ExportExcel, ExportMoreSheetExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'
import { localeString } from '@/utils/helper'
import { apiFormatAdaptor } from '@/utils/api'
import store from '@/store'

export default defineComponent({
  name: 'ExternalTransactionRecordList',
  components: {
    PageTitle,
    ExternalTransactionRecordTable,
    ExportOptionsDialog,
    ExportDialog,
    BaseSearch,
  },
  setup (props) {
    const router = useRouter()
    const { simpleFetch, fetchAll } = useFetch()
    const orgId = computed(() => get(store.state, 'org.org.id'))
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
    } = useTable()
    const { exportState, resetExport, getExportData, totalDataCount, totalPercentege } = useExport()
    const shopList = ref([])
    const sourceOptions = ref([])
    const modal = reactive({
      export: false,
    })
    const search = reactive({
      transactionId: null,
      source: null,
      timeRange: null,
      customerPhone: null,
      branchStores: [],
    })

    const onCreate = () => {
      router.push({ name: 'ItemCreatePage' })
    }

    const getTableData = async () => {
      const payload = {
        orgId: orgId.value,
        shopIds: search.branchStores.length ? join(map(search.branchStores, 'id'), ',') : undefined,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        transactionId: search.transactionId || undefined,
        source: search.source || undefined,
        customerPhone: search.customerPhone || undefined,
        transactionTimeStart: search.timeRange ? search.timeRange[0] : undefined,
        transactionTimeEnd: search.timeRange ? search.timeRange[1] : undefined,
      }
      await Promise.allSettled([
        fetchData(GetMemberExternalTransaction, payload),
        fetchDataCount(GetMemberExternalTransactionCount, payload),
      ])
    }

    const formatRecordData = (data) => {
      const sourceType = get(data, 'source') ? get(data, 'source').toLowerCase() : ''
      if (sourceType === originsConfig.pospal.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            分店: get(find(shopList.value, { id: data.ShopId }), 'name'),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            消費品項名稱: item.name,
            消費品項金額: localeString(item.price),
            消費品項數量: item.quantity,
            訂單備註: item.note,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      } else if (sourceType === originsConfig.opentix.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            分店: get(find(shopList.value, { id: data.ShopId }), 'name'),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            場次名稱: get(item, 'name'),
            消費金額: localeString(item.price),
            消費數量: item.quantity,
            每場起訖時間: `${formatDate(item.context.opentixEventStartTime * 1000)} ~ ${formatDate(item.context.opentixEventEndTime * 1000)}`,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      } else if (sourceType === originsConfig.weiby.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            分店: get(find(shopList.value, { id: data.ShopId }), 'name'),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            消費品項名稱: item.name,
            消費品項金額: localeString(item.price),
            消費品項數量: item.quantity,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      } else if (sourceType.toLowerCase() === originsConfig.drfoot.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            分店: get(find(shopList.value, { id: data.ShopId }), 'name'),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            消費品項名稱: item.name,
            消費編號: item.itemNo,
            消費金額: localeString(item.price),
            消費數量: item.quantity,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      }
      return []
    }

    const prepareExport = async () => {
      modal.export = false
      exportState.modal = true
      exportState.exportting = true
      const payload = {
        orgId: orgId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        transactionId: search.transactionId || undefined,
        source: search.source || undefined,
        customerPhone: search.customerPhone || undefined,
        transactionTimeStart: search.timeRange ? search.timeRange[0] : undefined,
        transactionTimeEnd: search.timeRange ? search.timeRange[1] : undefined,
      }
      const exportData = {
        pospal: {
          sheetName: 'Pospal',
          data: [],
        },
        weiby: {
          sheetName: 'Weiby',
          data: [],
        },
        opentix: {
          sheetName: 'Opentix',
          data: [],
        },
        drfoot: {
          sheetName: 'DrFoot',
          data: [],
        },
      }
      try {
        const res = await getExportData({
          stage: 0,
          fetchAPI: GetMemberExternalTransaction,
          payload,
        })
        exportState.dataCount = [res.length]
        if (!totalDataCount.value) {
          exportState.content = '尚無資料可匯出'
          exportState.error = true
          window.$message.warning('尚無資料可匯出')
          return
        }

        for (const data of res) {
          const sourceType = get(data, 'source') ? get(data, 'source').toLowerCase() : ''
          if (sourceType === originsConfig.pospal.value) exportData.pospal.data.push(...formatRecordData(data))
          else if (sourceType === originsConfig.weiby.value) exportData.weiby.data.push(...formatRecordData(data))
          else if (sourceType === originsConfig.opentix.value) exportData.opentix.data.push(...formatRecordData(data))
          else if (sourceType.toLowerCase() === originsConfig.drfoot.value) exportData.drfoot.data.push(...formatRecordData(data))
        }
        exportState.success = true
        exportState.content = '匯出完成'

        console.log(map(exportData, (sheet) => sheet))

        ExportMoreSheetExcel(map(exportData, (sheet) => sheet), '各分店消費紀錄')
      } catch (error) {
        console.log('export error: ', error)
        exportState.exportting = false
        exportState.error = true
        exportState.content = '匯出失敗'
      }
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onBeforeMount(async () => {
      simpleFetch(GetMemberExternalTransactionSourceOptions, { orgId: orgId.value }, (res) => {
        sourceOptions.value = map(res, (item) => {
          const origin = get(originsConfig, item)
          if (!origin) return { label: item, value: item }
          return origin
        })
      })

      console.log(1)

      fetchAll(apiFormatAdaptor(GetShops), null, (res) => {
        shopList.value = res
      })
    })

    onMounted(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      search,
      sourceOptions,
      resetExport,
      exportState,
      totalDataCount,
      modal,
      totalPercentege,
      prepareExport,
      GetShops,
      apiFormatAdaptor,
      shopList,
    }
  },
})
</script>
<style scoped lang="postcss">
::v-deep .el-range-separator {
  width: auto !important;
}
</style>
